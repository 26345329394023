<template>
  <v-container
    id="dashboard-view"
    fluid
    tag="section"
  >
    <v-row>
      <v-col cols="12">
        <v-row>
        <v-col
          cols="12"
          md="6"
          lg="3"
        >

          <material-stat-card v-bind="allperson_usenews">
          </material-stat-card>
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="3"
        >
          <material-stat-card v-bind="todayperson_usenews">
          </material-stat-card>
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="3"
        >
          <material-stat-card v-bind="allperson_clicknews">
          </material-stat-card>
        </v-col>
        <v-col
          cols="12"
          md="6"
          lg="3"
        >
          <material-stat-card v-bind="todayperson_clicknews">
          </material-stat-card>

        </v-col>
        </v-row>
        </v-col>
        <v-col
              cols="12"
              md="6"
              lg="6"
            >
              <material-chart-card
                :color="access_week.color"
                :data="access_week.data"
                :options="access_week.options"
                :responsive-options="access_week.responsiveOptions"
                :title="access_week.title"
                :type="access_week.type"
              >
                <template #subtitle>
                  <div class="font-weight-light text--secondary">
                    <div v-html="access_week.subtitle" />
                  </div>
                </template>
              </material-chart-card>
            </v-col>


            <v-col
              cols="12"
              md="6"
              lg="6"
            >
              <material-chart-card
                :color="countclickWeek.color"
                :data="countclickWeek.data"
                :options="countclickWeek.options"
                :responsive-options="countclickWeek.responsiveOptions"
                :title="countclickWeek.title"
                :type="countclickWeek.type"
              >
                <template #subtitle>
                  <div class="font-weight-light text--secondary">
                    <div v-html="countclickWeek.subtitle" />
                  </div>
                </template>
              </material-chart-card>
            </v-col>
        <v-col
          cols="12"
          md="12"
          lg="12"
        >
          <material-card
            color="orange"
            full-header
          >
            <template #heading>
              <div class="pa-8 white--text">
                <div class="text-h4 font-weight-light">
                  ความนิยมโฆษณา
                </div>
                <div class="text-caption">
                </div>
              </div>
            </template>
            <div style="height: 70vh; overflow-y: scroll;display: flex; justify-content: center;">
              <div style="margin-top: 10px; display: flex; justify-content: center; height: 64vh; overflow-y: scroll;width: 70vw;" id="myscroll" v-on:scroll="scrollFunction">
                <table style="width: 70vw;padding-left: 30px;">
                    <tr v-for="(item, index) in news_List" @click="openurl_news(item.url)" style="cursor: pointer;">
                      <td style="justify-content: center;display: flex;width: 100%;">
                        <v-img
                        :src="`${path}/api/v1/getfile/picture/${item.image}`"
                        :lazy-src="`${path}/api/v1/getfile/picture/${item.image}`"
                        class="grey lighten-2 alligator-turtle"
                        style="min-width: 70px;max-width: 100px; max-height: 100px;">
                        </v-img>
                      </td>
                      <td>
                        <div style="white-space: nowrap; width: 40vw; overflow: hidden; text-overflow: ellipsis; font-weight: 700;">{{item.title}}</div>
                        <!-- <div style="white-space: nowrap; width: 40vw; overflow: hidden; text-overflow: ellipsis;">{{item.description}}</div> -->
                      </td>
                      <td style="font-size: 13px;">
                        <v-icon style="color: gray; font-size: 13px;">visibility</v-icon>  {{item.total_click}}
                      </td>
                        <td style="font-size: 13px;">
                        <v-icon style="color: gray; font-size: 13px;">person</v-icon>  {{item.total_user}}
                      </td>
                    </tr>
                </table>
              </div>
            </div>
          </material-card>
        </v-col>
      </v-row>
  </v-container>
</template>

<script>
  // Utilities
  // import gql from 'graphql-tag'
  import { get } from 'vuex-pathify'
  import Vue from 'vue'
  var _ = require('lodash')
  var moment = require('moment')

  const lineSmooth = Vue.chartist.Interpolation.cardinal({
    tension: 0,
  })

// const query_accesstoday = gql`
// query MyQuery($daystart: timestamptz, $dayend: timestamptz) {
//   advertising_log(distinct_on: one_id, where: {_and: {create_at: {_gte: $daystart, _lte: $dayend}}}) {
//     create_at
//     one_id
//   }
// }
// `
//
// const query_clicktoday = gql`
// query MyQuery($daystart: timestamptz, $dayend: timestamptz) {
//   advertising_log_aggregate(where: {_and: {create_at: {_lte: $dayend, _gte: $daystart}}}) {
//     aggregate {
//       count(columns: one_id)
//     }
//   }
// }
// `
//
//
// const sub_accesstoday = gql`
// subscription MySubscription($daystart: timestamptz, $dayend: timestamptz) {
//   advertising_log(distinct_on: one_id, where: {_and: {create_at: {_lte: $dayend, _gte: $daystart}}}) {
//     create_at
//     one_id
//   }
// }
// `
//
// const sub_accesstodayall = gql`
// subscription MySubscription {
//   advertising_log(distinct_on: one_id) {
//     create_at
//     one_id
//   }
// }
// `
//
//
//
// const sub_clicktoday = gql`
// subscription MySubscription($daystart: timestamptz, $dayend: timestamptz) {
//   advertising_log_aggregate(where: {_and: {create_at: {_lte: $dayend, _gte: $daystart}}}) {
//     aggregate {
//       count(columns: one_id)
//     }
//   }
// }
// `
//
// // sub_clicktodayall
//
// const sub_clicktodayall = gql`
// subscription MySubscription {
//   advertising_log_aggregate {
//     aggregate {
//       count(columns: one_id)
//     }
//   }
// }
//
// `
//
// const query_news_MaxView = gql`
// query MyQuery($offset: Int) {
//   advertising(limit: 20, order_by: {ads_detail_aggregate: {count: desc}}, offset: $offset) {
//     ads_id
//     ads_link
//     ads_origin
//     ads_pic
//     ads_title
//     ads_detail {
//       ads_id
//       create_at
//       one_id
//       os
//       os_version
//       ua_name
//       ua_version
//       log_ads_id
//     }
//     create_at
//     type_click
//     update_at
//   }
// }
// `


  export default {
    name: 'DashboardView',
    // apollo: {
    //   $subscribe: {
    //     accesstoday: {
    //       query: sub_accesstoday,
    //       variables () {
    //         return {
    //           daystart: moment().format("YYYY-MM-DD") + "T00:00:00.000000+07:00",
    //           dayend: moment().format("YYYY-MM-DD") + "T24:00:00.000000+07:00",
    //         }
    //       },
    //       result (data) {
    //         // console.log(data.data.advertising_log.length)
    //         this.access_week.data.series[0][6] = data.data.advertising_log.length
    //         this.person_accessnews_week[6] = data.data.advertising_log.length
    //         this.todayperson_usenews = {  color: 'primary', icon: 'mdi-account-clock', title: 'ผู้อ่านโฆษณาวันนี้', value: data.data.advertising_log.length + ' คน' }
    //         // this.allperson_usenews = {  color: '#FD9A13', icon: 'mdi-account', title: 'ผู้อ่านโฆษณาทั้งหมด', value: data.data.advertising_log.length + ' คน' }
    //
    //         // Vue.set(this.access_week, 'data', {
    //         //   labels: [moment().subtract(6, "days").format("DD"), moment().subtract(5, "days").format("DD"), moment().subtract(4, "days").format("DD"), moment().subtract(3, "days").format("DD"), moment().subtract(2, "days").format("DD"), 'เมื่อวาน', 'วันนี้'],
    //         //   series: [this.person_accessnews_week],
    //         // })
    //         // Vue.set(this.access_week.options, 'high', _.max(this.person_accessnews_week))
    //       },
    //     },
    //     accessall: {
    //       query: sub_accesstodayall,
    //       variables () {
    //         return {
    //         }
    //       },
    //       result (data) {
    //         this.allperson_usenews = {  color: '#FD9A13', icon: 'mdi-account', title: 'ผู้อ่านโฆษณาทั้งหมด', value: data.data.advertising_log.length + ' คน' }
    //       },
    //     },
    //     clicktoday: {
    //       query: sub_clicktoday,
    //       variables () {
    //         return {
    //           daystart: moment().format("YYYY-MM-DD") + "T00:00:00.000000+07:00",
    //           dayend: moment().format("YYYY-MM-DD") + "T24:00:00.000000+07:00",
    //         }
    //       },
    //       result (data) {
    //         // console.log(data)
    //         this.countclickWeek.data.series[0][6] = data.data.advertising_log_aggregate.aggregate.count
    //         this.person_clicknews_week[6] = data.data.advertising_log_aggregate.aggregate.count
    //         this.todayperson_clicknews = {  color: 'info', icon: 'mdi-newspaper-variant-outline', title: 'อ่านโฆษณาวันนี้', value: data.data.advertising_log_aggregate.aggregate.count + ' ครั้ง' }
    //
    //         // Vue.set(this.countclickWeek, 'data', {
    //         //   labels: [moment().subtract(6, "days").format("DD"), moment().subtract(5, "days").format("DD"), moment().subtract(4, "days").format("DD"), moment().subtract(3, "days").format("DD"), moment().subtract(2, "days").format("DD"), 'เมื่อวาน', 'วันนี้'],
    //         //   series: [this.person_clicknews_week],
    //         // })
    //         // Vue.set(this.countclickWeek.options, 'high', _.max(this.person_clicknews_week))
    //       },
    //     },
    //     clickall: {
    //       query: sub_clicktodayall,
    //       variables () {
    //         return {
    //         }
    //       },
    //       result (data) {
    //         // console.log(data)
    //         this.allperson_clicknews = {  color: 'success', icon: 'mdi-newspaper-variant-multiple', title: 'อ่านโฆษณาทั้งหมด', value: data.data.advertising_log_aggregate.aggregate.count + ' ครั้ง' }
    //       },
    //     },
    //   },
    // },
    data() {
    return {
      xx: process.env.VUE_APP_secrete,
      path: process.env.VUE_APP_API,
      charts: [{
        type: 'Bar',
        color: 'primary',
        title: 'สถิติคนเข้าใช้งาน',
        subtitle: 'สถิติย้อนหลัง7วัน',
        time: 'updated 10 minutes ago',
        data: {
          labels: ['17', '18', '19', '20', '21', 'เมื่อวาน', 'วันนี้'],
          series: [
            [542, 443, 320, 780, 553, 453, 326],
          ],
        },
        options: {
          axisX: {
            showGrid: false,
          },
          low: 0,
          high: 1000,
          chartPadding: {
            top: 0,
            right: 5,
            bottom: 0,
            left: 0,
          },
        },
        responsiveOptions: [
          ['screen and (max-width: 640px)', {
            seriesBarDistance: 5,
            axisX: {
              labelInterpolationFnc: function (value) {
                return value[0]
              },
            },
          }],
        ],
      }, {
        type: 'Line',
        color: 'success',
        title: 'Daily Sales',
        subtitle: '<i class="mdi mdi-arrow-up green--text"></i><span class="green--text">55%</span>&nbsp;increase in today\'s sales',
        time: 'updated 4 minutes ago',
        data: {
          labels: ['12am', '3pm', '6pm', '9pm', '12pm', '3am', '6am', '9am'],
          series: [
            [230, 750, 450, 300, 280, 240, 200, 190],
          ],
        },
        options: {
          lineSmooth,
          low: 0,
          high: 1000, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
      }, {
        type: 'Line',
        color: 'info',
        title: 'Completed Tasks',
        subtitle: 'Last Campaign Performance',
        time: 'campaign sent 26 minutes ago',
        data: {
          labels: ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
          series: [
            [12, 17, 7, 17, 23, 18, 38],
          ],
        },
        options: {
          lineSmooth,
          low: 0,
          high: 50, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
          chartPadding: {
            top: 0,
            right: 0,
            bottom: 0,
            left: 0,
          },
        },
      }],
      tabs: 0,
      news_List: [],
      offset: 0,
      height: 0,
      allperson_usenews: {},
      allperson_clicknews: {},
      todayperson_usenews: {},
      todayperson_clicknews: {},
      yesterdayperson_usenews: {},
      yesterdayperson_clicknews: {},
      person_accessnews_week: [1,1,1,1,1,1,1],
      person_clicknews_week: [1,1,1,1,1,1,1],
      index_newsTop1: {  color: 'info', icon: 'mdi-newspaper-variant-outline', title: 'ลำดับโฆษณา', value: '-' },
      countclickWeek: {
        type: 'Line',
        color: 'info',
        title: 'สถิติการกดอ่านโฆษณา',
        subtitle: 'ประวัติการกดอ่านโฆษณาย้อนหลัง 7 วัน',
        // time: 'updated 10 minutes ago',
        data: {
          labels: ['-', '-', '-', '-', '-', 'เมื่อวาน', 'วันนี้'],
          series: [[0,0,0,0,0,0,0]],
        },
        options: {
          axisX: {
            showGrid: false,
          },
          low: 0,
          high: 100,
          responsive: true,
          maintainAspectRatio: false,
          chartPadding: {
            top: 0,
            right: 5,
            bottom: 0,
            left: 0,
          },
        },
        responsiveOptions: [
          ['screen and (max-width: 640px)', {
            seriesBarDistance: 5,
            axisX: {
              labelInterpolationFnc: function (value) {
                return value[0]
              },
            },
          }],
        ],
      },
      access_week: {
        type: 'Line',
        color: 'primary',
        title: 'สถิติคนเข้าใช้งาน',
        subtitle: 'สถิติย้อนหลัง 7 วัน',
        time: 'updated 10 minutes ago',
        data: {
          labels: ['-', '-', '-', '-', '-', 'เมื่อวาน', 'วันนี้'],
          series: [[0,0,0,0,0,0,0]],
        },
        options: {
          axisX: {
            showGrid: false,
          },
          low: 0,
          high: 100,
          responsive: true,
          maintainAspectRatio: false,
          chartPadding: {
            top: 0,
            right: 5,
            bottom: 0,
            left: 0,
          },
        },
        responsiveOptions: [
          ['screen and (max-width: 640px)', {
            seriesBarDistance: 5,
            axisX: {
              labelInterpolationFnc: function (value) {
                return value[0]
              },
            },
          }],
        ],
      }
    }},
    computed: {
      sales: get('sales/sales'),
      totalSales () {
        return this.sales.reduce((acc, val) => acc + val.salesInM, 0)
      },
    },
    watch: {
      person_accessnews_week: {
        deep: true,
        handler () {
        }
      },
    },
    created () {
      this.getTotalStaticNumber()
      this.getCountClickWeekNumber()
      this.getDetailNews()
      document.title = 'News Analytics'
    },
    methods: {
      getDetailNews () {
        this.axios.get(process.env.VUE_APP_API + `/api/v1/dashboard/analytic-ads?limit=10&last_index=${this.offset}`).then((response) => {
          if (response.data.status === 200) {
            // console.log(response.data.data)
            this.news_List.push.apply(this.news_List, response.data.data)
            this.news_List = _.unionBy(this.news_List, 'ads_id')
            if (this.offset == 0) {
              var scroll = this.$el.querySelector('#myscroll')
              this.height = scroll.scrollHeight - scroll.scrollTop
            }
          }
        }).catch(error => {
          console.log(error)
        })
      },
      getCountClickWeekNumber () {
        this.axios.get(process.env.VUE_APP_API + `/api/v1/dashboard/history-ads-statistic-number`).then((response) => {
          if (response.data.status === 200) {
            // console.log(response.data.data)
            var access_week = response.data.data.list_count_user.reverse()
            var countclickWeek = response.data.data.list_count_read.reverse()
            Vue.set(this.access_week, 'data', {
              labels: [moment().subtract(6, "days").format("DD"), moment().subtract(5, "days").format("DD"), moment().subtract(4, "days").format("DD"), moment().subtract(3, "days").format("DD"), moment().subtract(2, "days").format("DD"), 'เมื่อวาน', 'วันนี้'],
              series: [_.map(access_week, 'total_user')],
            })
            Vue.set(this.access_week.options, 'high', _.max(_.map(access_week, 'total_user')))

            Vue.set(this.countclickWeek, 'data', {
              labels: [moment().subtract(6, "days").format("DD"), moment().subtract(5, "days").format("DD"), moment().subtract(4, "days").format("DD"), moment().subtract(3, "days").format("DD"), moment().subtract(2, "days").format("DD"), 'เมื่อวาน', 'วันนี้'],
              series: [_.map(countclickWeek, 'total_user')],
            })
            Vue.set(this.countclickWeek.options, 'high', _.max(_.map(countclickWeek, 'total_user')))
          }
        }).catch(error => {
          console.log(error)
        })
      },
      getTotalStaticNumber () {
        this.axios.get(process.env.VUE_APP_API + `/api/v1/dashboard/total-ads-statisticnumber`).then((response) => {
          if (response.data.status === 200) {
            // console.log(response.data.data)
            this.allperson_usenews = {  color: '#FD9A13', icon: 'mdi-account', title: 'ผู้อ่านโฆษณาทั้งหมด', value: response.data.data.total_ads_user_read + ' คน' }
            this.todayperson_usenews = {  color: 'primary', icon: 'mdi-account-clock', title: 'ผู้อ่านโฆษณาวันนี้', value: response.data.data.total_ads_user_read_today + ' คน' }
            this.allperson_clicknews = {  color: 'success', icon: 'mdi-newspaper-variant-multiple', title: 'อ่านโฆษณาทั้งหมด', value: response.data.data.total_ads_click + ' ครั้ง' }
            this.todayperson_clicknews = {  color: 'info', icon: 'mdi-newspaper-variant-outline', title: 'อ่านโฆษณาวันนี้', value: response.data.data.total_ads_read_today + ' ครั้ง' }
          }
        }).catch(error => {
          console.log(error)
        })
      },
      scrollFunction() {
        var scroll = this.$el.querySelector('#myscroll')
        if (scroll.scrollTop >= ((scroll.scrollHeight - this.height-200.0))) {
          this.loadmoredetail()
        }
      },
      openurl_news (url) {
        window.open(url)
      },
      checkNumberOfperson (person) {
        return _.unionBy(person, 'one_id').length
      },
      loadmoredetail () {
        this.offset = this.offset + 1
        this.getDetailNews()
      },
    },
  }
</script>
